import React, { useState } from "react";
import {
    Card,
    CardContent,
    Button,
    TextField,
    Grid,
    IconButton,
    Menu,
    MenuItem
} from "@material-ui/core";

import MeaningIcons, { meaningIconIds } from "./MeaningIcons";

export default function(props) {
    const meaningButton = props.meaningButton;
    const handleChange = props.handleChange;
    const [meaningIconColorAnchorEl, setMeaningIconAnchorEl] = useState(null);

    return (
        <Card style={{ width: "100%" }}>
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    align="center"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <Grid container justify="center" align="center">
                            <Grid item xs={6}>
                                <IconButton
                                    style={{ border: "1px solid black" }}
                                    onClick={e => {
                                        setMeaningIconAnchorEl(e.currentTarget);
                                    }}
                                >
                                    <MeaningIcons
                                        iconId={meaningButton.iconId}
                                    ></MeaningIcons>
                                </IconButton>

                                <Menu
                                    anchorEl={meaningIconColorAnchorEl}
                                    open={Boolean(meaningIconColorAnchorEl)}
                                    onClose={() => {
                                        setMeaningIconAnchorEl(null);
                                    }}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                >
                                    {meaningIconIds.map(iconId => {
                                        return (
                                            <MenuItem
                                                selected={
                                                    iconId &&
                                                    iconId ===
                                                        meaningButton.iconId
                                                }
                                                key={iconId}
                                                onClick={() => {
                                                    meaningButton.iconId = iconId;
                                                    handleChange(meaningButton);
                                                    setMeaningIconAnchorEl(
                                                        null
                                                    );
                                                }}
                                            >
                                                <MeaningIcons
                                                    iconId={iconId}
                                                ></MeaningIcons>
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="name"
                                    value={meaningButton.name}
                                    onChange={e => {
                                        meaningButton.name = e.target.value;
                                        handleChange(meaningButton);
                                    }}
                                />
                            </Grid>{" "}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
