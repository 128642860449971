import React, { useState, useContext, useRef } from "react";
import { Button, Card, CardContent, CircularProgress } from "@material-ui/core";
import moment from "moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import VisitReport1Context from "./VisitReport1Context";
import ReactToPrint from "react-to-print";

export default function(props) {
    const [startDate, setStartDate] = useState(moment("0", "h").unix() * 1000);
    const [endDate, setEndDate] = useState(
        moment("0", "h")
            .add(1, "d")
            .unix() * 1000
    );

    const reportCtx = useContext(VisitReport1Context);
    const { printRef } = reportCtx;

    return (
        <Card>
            <CardContent>
                <div
                    style={{
                        padding: "5px",
                        display: "flex",
                        justifyContent: "space-evenly"
                    }}
                >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div>
                            <KeyboardDatePicker
                                value={startDate}
                                onChange={e => {
                                    const timestamp = e.unix() * 1000;
                                    setStartDate(timestamp);
                                }}
                            ></KeyboardDatePicker>
                        </div>
                        <div>
                            <KeyboardDatePicker
                                value={endDate}
                                onChange={e => {
                                    const timestamp = e.unix() * 1000;
                                    setEndDate(timestamp);
                                }}
                            ></KeyboardDatePicker>
                        </div>
                    </MuiPickersUtilsProvider>
                    <div>
                        <Button
                            disabled={reportCtx.fetching}
                            variant="contained"
                            onClick={() => {
                                reportCtx.getReport(startDate, endDate);
                            }}
                        >
                            get
                        </Button>
                        {reportCtx.fetching && (
                            <CircularProgress
                                style={{ position: "absolute" }}
                            ></CircularProgress>
                        )}
                    </div>
                    {/* <div>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Button variant="contained">print</Button>
                                );
                            }}
                            content={() => {
                                return printRef.current;
                            }}
                        ></ReactToPrint>
                    </div> */}
                </div>
            </CardContent>
        </Card>
    );
}
