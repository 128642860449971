import { createContext } from "react";

export const BillingV2Context = createContext({
    subscriptions: [],
    getSubscriptions: () => {},
    invoiceEstimates: {},
    getInvoiceEstimates: () => {},
    plans: [],
    getPlans: () => {}
});
