import firebase from "../../firebase";

export default function({ officeId, roomIds }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "pagingAdmin",
        funcName: "reorderRooms",
        data: {
            officeId,
            roomIds
        }
    });
}
