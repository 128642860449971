import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardActions,
    Button,
    CardContent,
    TextField,
    Grid
} from "@material-ui/core";
import firebase from "../../firebase";
import { appContext } from "../../contexts/app";

export default React.forwardRef((props, ref) => {
    const handleClose = props.handleClose;
    const [email, setEmail] = useState(props.email);
    const [loading, setLoading] = useState(false);
    const { error } = useContext(appContext);
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardContent>
                <Grid container align="center">
                    <Grid item xs={12}>
                        <TextField
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                            label="email"
                            type="text"
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    onClick={() => {
                        setLoading(true);
                        firebase
                            .auth()
                            .sendPasswordResetEmail(email)
                            .then(result => {
                                setLoading(false);
                                handleClose();
                            })
                            .catch(err => {
                                error.setMessage(err.message);
                            });
                    }}
                    disabled={!email || email.length < 1}
                    variant="contained"
                >
                    send password reset email
                </Button>
            </CardActions>
        </Card>
    );
});
