import firebase from "../../firebase";

export default function({ officeId, name, passwordProtected, password }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "messagingAdmin",
        funcName: "addUser",
        data: {
            officeId,
            name,
            passwordProtected,
            password
        }
    });
}
