import { useContext, useState, useRef } from "react";
import { OfficeContext } from "../../OfficeContext";
import firebase from "../../../../firebase";

export default function(props) {
    const { officeId } = useContext(OfficeContext);

    const [fetching, setFetching] = useState(false);
    const [visits, setVisits] = useState([]);
    const [stages, setStages] = useState([]);
    const [reportTime, setReportTime] = useState(0);
    const [reportStartDate, setReportStartDate] = useState(0);
    const [reportEndDate, setReportEndDate] = useState(0);
    const printRef = useRef(null);
    const printRef2 = useRef(null);

    function getReport(startDate, endDate) {
        setFetching(true);
        firebase
            .functions()
            .httpsCallable("allFunctions")({
                funcRole: "reporting",
                funcName: "getVisitReport1",
                data: {
                    officeId: officeId,
                    startDate,
                    endDate
                }
            })
            .then(result => {
                const reportData = result.data;
                const stages = Object.keys(reportData.stages)
                    .sort((idA, idB) => {
                        const nameA =
                            reportData.stages[idA] &&
                            reportData.stages[idA].name
                                ? reportData.stages[idA].name
                                : "zzz";
                        const nameB =
                            reportData.stages[idB] &&
                            reportData.stages[idB].name
                                ? reportData.stages[idB].name
                                : "zzz";

                        return nameA.localeCompare(nameB);
                    })
                    .map(id => {
                        return reportData.stages[id];
                    });
                setStages(stages);
                setVisits(reportData.visits);
                setReportTime(reportData.reportTime);
                setReportStartDate(reportData.startDate);
                setReportEndDate(reportData.endDate);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setFetching(false);
            });
    }
    return {
        getReport,
        fetching,
        stages,
        visits,
        reportTime,
        reportStartDate,
        reportEndDate,
        printRef,
        printRef2
    };
}
