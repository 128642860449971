import React, { useState, useContext } from "react";
import {
    TextField,
    Button,
    Card,
    CardContent,
    Grid,
    Modal
} from "@material-ui/core";
import firebase from "../../firebase";
import { mainRouterHistory } from "../../routerHistories";
import ForgotPassword from "./ForgotPassword";
import { appContext } from "../../contexts/app";
import { authContext } from "../../contexts/auth";

export default React.forwardRef((props, ref) => {
    const { error } = useContext(appContext);
    const auth = useContext(authContext);
    const handleClose = props.handleClose;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardContent>
                <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                    align="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <TextField
                            label="email"
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                            inputProps={{ style: { textAlign: "center" } }}
                            placeholder="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="password"
                            type="password"
                            value={password}
                            onChange={e => {
                                setPassword(e.target.value);
                            }}
                            inputProps={{ style: { textAlign: "center" } }}
                            placeholder="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            justify="center"
                            align="center"
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    disabled={
                                        email.length < 1 && password.length < 1
                                    }
                                    variant="contained"
                                    onClick={() => {
                                        firebase
                                            .auth()
                                            .signInWithEmailAndPassword(
                                                email,
                                                password
                                            )
                                            .then(result => {
                                                mainRouterHistory.push(
                                                    "/dashboard"
                                                );
                                                handleClose && handleClose();
                                            })
                                            .catch(err => {
                                                error.setMessage(err.message);
                                            });
                                    }}
                                >
                                    Sign In
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={
                                        email.length < 1 && password.length < 1
                                    }
                                    variant="contained"
                                    onClick={() => {
                                        firebase
                                            .auth()
                                            .createUserWithEmailAndPassword(
                                                email,
                                                password
                                            )
                                            .then(result => {
                                                mainRouterHistory.push(
                                                    "/dashboard"
                                                );
                                                handleClose && handleClose();
                                            })
                                            .catch(err => {
                                                error.setMessage(err.message);
                                            });
                                    }}
                                >
                                    sign up
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => {
                                setForgotPasswordOpen(true);
                            }}
                            size="small"
                        >
                            forgot password
                        </Button>
                        <Modal
                            style={{ display: "flex" }}
                            open={forgotPasswordOpen}
                            onClose={() => {
                                setForgotPasswordOpen(false);
                            }}
                        >
                            <ForgotPassword
                                email={email}
                                handleClose={() => {
                                    setForgotPasswordOpen(false);
                                }}
                            />
                        </Modal>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
