import React, { useState, useEffect, useContext } from "react";
import {
    useSubscriptions,
    BillingContext,
    usePaymentSources,
    usePlans,
    useCustomer
} from "./BillingContext";
import firebase from "../../../firebase";
import { Card, CardContent, Button, Grid, CardHeader } from "@material-ui/core";
import { mainRouterHistory } from "../../../routerHistories";
import Licenses from "./Licenses/Licenses";
import PaymentSources from "./PaymentSources/PaymentSources";
import Subscriptions from "./Subscriptions/Subscriptions";
import Customer from "./Customer/Customer";
import { OfficeContext } from "../OfficeContext";

export default function(props) {
    const [billingDocState, setBillingDocState] = useState("loading");
    const [licenseDocState, setLicenseDocState] = useState(null);
    const licenses =
        licenseDocState && licenseDocState.licenses
            ? licenseDocState.licenses
            : {};
    const { officeId, officeName } = useContext(OfficeContext);
    const subscriptions = useSubscriptions(officeId);
    const paymentSources = usePaymentSources(officeId);
    const customer = useCustomer(officeId);
    const plans = usePlans();

    useEffect(() => {
        subscriptions.getSubs();
        plans.getPlans();
        paymentSources.getSources();
        customer.getCustomer();

        const unsubBilling = firebase
            .firestore()
            .doc(`/offices/${officeId}/admin/billing`)
            .onSnapshot(
                doc => {
                    if (doc.data().active) {
                        setBillingDocState("active");
                    } else {
                        setBillingDocState("inactive");
                    }
                },
                err => {
                    console.error(err);
                    setBillingDocState("error");
                }
            );

        const unsubLicenses = firebase
            .firestore()
            .doc(`/offices/${props.location.state.officeId}/admin/licenses`)
            .onSnapshot(doc => {
                setLicenseDocState(doc.data());
            });

        return () => {
            unsubBilling();
            unsubLicenses();
        };
    }, []);

    return (
        <BillingContext.Provider
            value={{ subscriptions, paymentSources, plans, customer }}
        >
            <Grid
                style={{ width: "100%", margin: "0 auto" }}
                container
                spacing={8}
                justify="space-between"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={officeName}
                            subheader={billingDocState}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Customer officeId={officeId} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <PaymentSources officeId={officeId} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Subscriptions
                        officeId={officeId}
                        subscriptions={subscriptions.subs}
                        loading={subscriptions.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Licenses licenses={licenses} officeId={officeId} />
                </Grid>
            </Grid>
        </BillingContext.Provider>
    );
}
