import firebase from "../../firebase";

export default function({ officeId, personIds }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "pagingAdmin",
        funcName: "reorderPersons",
        data: {
            officeId,
            personIds
        }
    });
}
