import firebase from "../../firebase";

export default function({
    officeId,
    name,
    passwordProtected,
    password,
    userId
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "messagingAdmin",
        funcName: "updateUser",
        data: {
            officeId,
            name,
            passwordProtected,
            password,
            userId
        }
    });
}
