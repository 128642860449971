import React, { useContext, forwardRef } from "react";
import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
} from "@material-ui/core";

import moment from "moment";
import VisitReport1Context from "../../VisitReport1Context";

export default forwardRef((props, ref) => {
    const reportCtx = useContext(VisitReport1Context);
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Patient Name</TableCell>
                    <TableCell>Arrival Time</TableCell>
                    <TableCell>Appointment Time</TableCell>
                    <TableCell>Checkout Time</TableCell>
                    <TableCell>Total Visit Time</TableCell>
                    {reportCtx.stages.map((stage) => {
                        return (
                            <TableCell key={stage.id}>{stage.name}</TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {reportCtx.visits
                    .sort((a, b) => {
                        return a.arrivalTime - b.arrivalTime;
                    })
                    .map((visit) => {
                        return (
                            <TableRow key={visit.id}>
                                <TableCell>{visit.name}</TableCell>
                                <TableCell>
                                    {moment(visit.arrivalTime).format("LT")}
                                </TableCell>
                                <TableCell>
                                    {moment(visit.appointmentTime).format("LT")}
                                </TableCell>
                                <TableCell>
                                    {visit.completedTime
                                        ? moment(visit.completedTime).format(
                                              "LT"
                                          )
                                        : "-"}
                                </TableCell>
                                <TableCell>
                                    {visit.completedTime
                                        ? Math.floor(
                                              (parseInt(visit.completedTime) -
                                                  parseInt(visit.arrivalTime)) /
                                                  60000
                                          )
                                        : 0}
                                </TableCell>
                                {reportCtx.stages.map((stage) => {
                                    const timeInStage = Math.floor(
                                        visit.stageTimes[stage.id] / 60000
                                    );
                                    return (
                                        <TableCell key={visit.id + stage.id}>
                                            {timeInStage || 0}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
});
