import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Collapse,
    IconButton,
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    Modal,
    TextField,
    CircularProgress
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import EditIcon from "@material-ui/icons/Edit";
import { OfficeContext } from "../../OfficeContext";
import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../contexts/app";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default React.forwardRef((props, ref) => {
    const handleClose = props.handleClose;
    const { subscriptions } = useContext(BillingContext);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const subscription = props.subscription;
    const [jsonOpen, setJsonOpen] = useState(false);
    const [quantityModalOpen, setQuantityModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Subscription Details" />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={`License Quantity: ${
                                subscription.plan_quantity
                            }`}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                onClick={() => {
                                    setQuantityModalOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                            <Modal
                                style={{ display: "flex" }}
                                open={quantityModalOpen}
                                onClose={() => {
                                    setQuantityModalOpen(false);
                                }}
                            >
                                <ChangeQuantity
                                    handleClose={() => {
                                        setQuantityModalOpen(false);
                                        handleClose();
                                    }}
                                    subscriptionId={subscription.id}
                                    quantity={subscription.plan_quantity}
                                />
                            </Modal>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Unit Price: ${(
                                subscription.plan_unit_price / 100
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Total Cost: ${(
                                subscription.plan_amount / 100
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}`}
                        />
                    </ListItem>

                    {subscription.has_scheduled_changes && (
                        <ListItem>
                            <ListItemText
                                style={{ color: "green" }}
                                primary="has scheduled changes"
                            />
                        </ListItem>
                    )}
                </List>
            </CardContent>
            <CardContent>
                <IconButton
                    onClick={() => {
                        setJsonOpen(!jsonOpen);
                    }}
                >
                    {jsonOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
                <Collapse in={jsonOpen} timeout="auto">
                    <pre>{JSON.stringify(subscription, null, 4)}</pre>
                </Collapse>
            </CardContent>
            <CardActions>
                {subscription.status === "active" && (
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setLoading(true);
                            insyncHttps.billing
                                .cancelSubscription({
                                    subscriptionId: subscription.id,
                                    officeId
                                })
                                .then(result => {
                                    setLoading(false);
                                    console.log(result);
                                    subscriptions.getSubs();
                                    handleClose();
                                })
                                .catch(err => {
                                    setLoading(false);
                                    error.setMessage(err.message);
                                });
                        }}
                        variant="contained"
                    >
                        Cancel Subscription
                    </Button>
                )}
                {loading && <CircularProgress size={10} />}
            </CardActions>
        </Card>
    );
});

const ChangeQuantity = React.forwardRef((props, ref) => {
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { subscriptions } = useContext(BillingContext);
    const handleClose = props.handleClose;
    const subscriptionId = props.subscriptionId;
    const [quantity, setQuantity] = useState(props.quantity);
    const [loading, setLoading] = useState(false);
    return (
        <Card style={{ margin: "auto" }} ref={ref}>
            <CardHeader title="Subscription Quantity" />
            <CardContent>
                <TextField
                    type="number"
                    value={quantity}
                    onChange={e => {
                        setQuantity(e.target.value);
                    }}
                />
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.billing
                            .changeSubscriptionQuantity({
                                officeId,
                                subscriptionId,
                                quantity
                            })
                            .then(result => {
                                setLoading(false);
                                handleClose();
                                subscriptions.getSubs();
                            })
                            .catch(err => {
                                setLoading(false);
                                console.log(err);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    SUBMIT
                </Button>
                {loading && <CircularProgress size={10}> </CircularProgress>}
            </CardActions>
        </Card>
    );
});
