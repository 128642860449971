import React, { useState, useContext } from "react";
import firebase from "../../../firebase";
import {
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Button,
    TextField,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    Grid,
    CircularProgress,
    Modal
} from "@material-ui/core";
import { appContext } from "../../../contexts/app";
import { OfficeContext } from "../OfficeContext";
import { UsersContext } from "./UsersContext";
import insyncHttps from "../../../insyncHttps/insyncHttps";

export default React.forwardRef((props, ref) => {
    const [email, setEmail] = useState("");
    const [selectedRole, setSelectedRole] = useState("");
    const [userSearchState, setUserSearchState] = useState("init");
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const users = useContext(UsersContext);
    const handleClose = props.handleClose;
    const [loading, setLoading] = useState(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);

    const addUserSequence = async () => {
        setLoading(true);

        const userExists = await new Promise((resolve, reject) => {
            insyncHttps.insyncUser
                .doesUserExist({ email })
                .then(result => {
                    setLoading(false);
                    resolve(result.data);
                })
                .catch(err => {
                    error.setMessage(err.message);
                    setLoading(false);
                    reject(err);
                });
        });

        if (userExists) {
            setLoading(true);
            insyncHttps.insyncUser
                .addOfficeRoleToUser({
                    officeId,
                    email,
                    roleName: selectedRole
                })
                .then(result => {
                    setLoading(false);
                    users.getUsers();
                    handleClose();
                })
                .catch(err => {
                    setLoading(false);
                    error.setMessage(err.message);
                });
        } else {
            setCreateUserOpen(true);
        }
    };

    return (
        <Card style={{ margin: "auto" }}>
            <CardHeader title="Add User To Office" />
            <CardContent>
                <Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label={"Insync user email"}
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            <InputLabel htmlFor="add-role">Role</InputLabel>
                            <Select
                                inputProps={{ name: "Role", id: "add-role" }}
                                value={selectedRole}
                                onChange={e => {
                                    setSelectedRole(e.target.value);
                                }}
                            >
                                <MenuItem value={"billingAdmin"}>
                                    billingAdmin
                                </MenuItem>
                                <MenuItem value={"officeAdmin"}>
                                    officeAdmin
                                </MenuItem>
                                <MenuItem value={"officeUser"}>
                                    officeUser
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button variant="contained" onClick={addUserSequence}>
                    add
                </Button>
                {loading ? <CircularProgress size={10} /> : null}
            </CardActions>
            <Modal
                style={{ display: "flex" }}
                open={createUserOpen}
                onClose={() => {
                    setCreateUserOpen(false);
                }}
            >
                <CreateUser
                    email={email}
                    handleClose={() => {
                        setCreateUserOpen(false);
                    }}
                />
            </Modal>
        </Card>
    );
});

const CreateUser = React.forwardRef((props, ref) => {
    const users = useContext(UsersContext);
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const email = props.email;
    const [loading, setLoading] = useState(false);
    const handleClose = props.handleClose;
    const closeAll = props.closeAll;
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Create User?" />
            <CardContent>
                A user does not exist for email address {email}. Would you like
                to create the user?
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.insyncUser
                            .createUser({ officeId, email })
                            .then(result => {
                                setLoading(false);
                                alert(`user created. password: ${result.data}`);
                                handleClose();
                            })
                            .catch(err => {
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    Create
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardActions>
        </Card>
    );
});
