import React, { useState, useContext } from "react";

import ReportContainer from "./ReportContainer";
import { Card, CardContent, Button } from "@material-ui/core";
import VisitReport1Context from "../../VisitReport1Context";
import ReactToPrint from "react-to-print";

export default function (props) {
    const reportCtx = useContext(VisitReport1Context);

    return (
        <Card>

            <CardContent>
                {reportCtx.visits.length > 0 && (
                    <ReportContainer></ReportContainer>
                )}
            </CardContent>
        </Card>
    );
}
