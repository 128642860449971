import firebase from "../../firebase";

export default function({
    officeId,
    personId,
    bgColor,
    textColor,
    name,
    soundId,
    phoneNumber,
    textOnPage
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "pagingAdmin",
        funcName: "updatePerson",
        data: {
            officeId,
            personId,
            bgColor,
            textColor,
            name,
            soundId,
            phoneNumber,
            textOnPage
        }
    });
}
