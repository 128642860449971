import React, { useContext, useRef, createRef } from "react";
import NavBar from "./components/NavBar";
import { Router, Route } from "react-router-dom";
import { mainRouterHistory } from "./routerHistories";
import StartPage from "./components/StartPage";
import Dashboard from "./components/Dashboard/Dashboard";
import { useAuth, authContext } from "./contexts/auth";
import Office from "./components/Office/Office";
import { appContext, useApp } from "./contexts/app";
import {
    Modal,
    Card,
    CardContent,
    CardHeader,
    Button
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme/theme";

function App() {
    const appState = useApp();

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <appContext.Provider value={appState}>
                    <authContext.Provider value={useAuth()}>
                        <NavBar />

                        <Router history={mainRouterHistory}>
                            <Route exact path="/" component={StartPage} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/office" component={Office} />
                        </Router>
                    </authContext.Provider>
                    <Modal
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex"
                        }}
                        open={
                            appState.error.message &&
                            appState.error.message.length > 0
                                ? true
                                : false
                        }
                        onClose={() => {
                            appState.error.setMessage(null);
                        }}
                    >
                        <Card style={{ margin: "auto" }}>
                            <CardHeader title="Error" />
                            <CardContent>{appState.error.message}</CardContent>
                        </Card>
                    </Modal>
                </appContext.Provider>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
