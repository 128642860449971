import React, { useContext, useState, useEffect } from "react";
import { authContext } from "../../contexts/auth";
import {
    List,
    ListItem,
    Grow,
    Grid,
    CardContent,
    Card,
    CardHeader,
    Paper,
    Button,
    TextField,
    Dialog,
    CardActions,
    CardActionArea,
    CircularProgress,
    ListItemText
} from "@material-ui/core";
import { mainRouterHistory } from "../../routerHistories";
import { DashboardContext } from "./DashboardContext";
import insyncHttps from "../../insyncHttps/insyncHttps";

export default function(props) {
    const { officesMeta, getOfficeList, error, loading } = useContext(
        DashboardContext
    );
    const [createOfficeDialogOpen, setCreateOfficeDialogOpen] = useState(false);

    return (
        <Card>
            <CardHeader title={"Offices"} />
            <CardContent>
                {loading ? (
                    <CircularProgress size={30} />
                ) : (
                    <List>
                        {Object.keys(officesMeta)
                            .sort((a, b) => {
                                const nameA = officesMeta[a].name;
                                const nameB = officesMeta[b].name;

                                if (nameA > nameB) {
                                    return 1;
                                }
                                if (nameA < nameB) {
                                    return -1;
                                }
                                return 0;
                            })
                            .map(officeId => {
                                return (
                                    <ListItem
                                        button
                                        divider
                                        key={officeId}
                                        onClick={() => {
                                            mainRouterHistory.push("/office", {
                                                officeId: officeId,
                                                officeName:
                                                    officesMeta[officeId].name
                                            });
                                        }}
                                    >
                                        <ListItemText
                                            primary={officesMeta[officeId].name}
                                        />
                                    </ListItem>
                                );
                            })}
                    </List>
                )}
            </CardContent>
            <CardContent>
                <Button
                    variant="contained"
                    onClick={() => {
                        setCreateOfficeDialogOpen(true);
                    }}
                >
                    create office
                </Button>
            </CardContent>
            <Dialog
                open={createOfficeDialogOpen}
                onClose={() => {
                    setCreateOfficeDialogOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <NewOffice
                            getOfficeList={getOfficeList}
                            handleClose={() => {
                                setCreateOfficeDialogOpen(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Card>
    );
}

function NewOffice(props) {
    const auth = useContext(authContext);
    const [name, setName] = useState("");
    const getOfficeList = props.getOfficeList;
    const handleClose = props.handleClose;
    const [loading, setLoading] = useState(false);
    return (
        <Card style={{ margin: "auto" }}>
            <CardHeader title="Create Office" />
            <CardContent>
                <TextField
                    value={name}
                    onChange={e => {
                        setName(e.target.value);
                    }}
                    label="Name"
                />
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    disabled={name.length < 1}
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.office
                            .createOffice({ name })
                            .then(async result => {
                                await auth.reload();
                                setLoading(false);
                                getOfficeList();
                                handleClose();
                            })
                            .catch(err => {
                                setLoading(false);
                            });
                    }}
                >
                    Create Office
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardActions>
        </Card>
    );
}
