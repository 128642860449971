import React from "react";
import { Typography } from "@material-ui/core";
import Login from "./Login/Login";

export default function(props) {
    return (
        <Login />
        // <Typography align="center" variant="h1" component="h1">
        //     INSYNC
        // </Typography>
    );
}
