import React, { createContext, useState } from "react";

export const appContext = createContext({
    error: { message: null, setMessage: () => {} }
});

export const useApp = () => {
    const [message, setMessage] = useState("");
    return { error: { message, setMessage } };
};
