import React from "react";
import VisitReport from "./VisitReport/VisitReport";
import VisitReport1 from "./VisitReport1/VisitReport1";

export default function (props) {
    return (
        <div>
            {/* <VisitReport></VisitReport> */}
            <VisitReport1></VisitReport1>
        </div>
    );
}
