import React, { useState, useEffect } from "react";
import firebase from "../../../firebase";
import {
    CardContent,
    Card,
    CardHeader,
    Modal,
    CardActions,
    IconButton,
    ListItem,
    ListItemText
} from "@material-ui/core";
import UserDetails from "./UserDetails";
export default function(props) {
    const user = props.user;
    const [userDetailsOpen, setUserDetailsOpen] = useState(false);
    return (
        <React.Fragment>
            <ListItem
                button
                divider
                onClick={() => {
                    setUserDetailsOpen(true);
                }}
            >
                <ListItemText
                    primary={user.email}
                    secondary={user.roles.join(",")}
                />
            </ListItem>
            <Modal
                style={{ display: "flex" }}
                open={userDetailsOpen}
                onClose={() => {
                    setUserDetailsOpen(false);
                }}
            >
                <UserDetails user={user} />
            </Modal>
        </React.Fragment>
    );
}
