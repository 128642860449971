import React, { useContext } from "react";
import ReportTable from "./ReportTable";
import VisitReport1Context from "../../VisitReport1Context";
import moment from "moment";
import { Card, CardContent, Button } from "@material-ui/core";
import ReactToPrint from "react-to-print";

export default function (props) {
    const reportCtx = useContext(VisitReport1Context);
    const reportTime = moment(reportCtx.reportTime).format("hh:mm YYYY/MM/DD ");
    const endDate = moment(reportCtx.reportEndDate).format("YYYY/MM/DD");
    const startDate = moment(reportCtx.reportStartDate).format("YYYY/MM/DD");
    const { printRef2 } = reportCtx;

    return (
        <Card style={{ height: "500px", overflowY: "scroll" }}>
            <ReactToPrint
                trigger={() => {
                    return (
                        <Button variant="contained" size="small">
                            print
                        </Button>
                    );
                }}
                content={() => {
                    return printRef2.current;
                }}
            ></ReactToPrint>
            <CardContent ref={printRef2}>
                <div>
                    <div>{`Generated at ${reportTime}`}</div>
                    <div>{`${startDate} - ${endDate}`}</div>
                </div>
                <ReportTable></ReportTable>
            </CardContent>
        </Card>
    );
}
