import firebase from "../../firebase";

export default function({ officeId, licenseId, name }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "billingAdmin",
        funcName: "renameLicense",
        data: {
            officeId,
            licenseId,
            name
        }
    });
}
