import React, { useState, useEffect, useContext } from "react";
import User from "./User";
import {
    CircularProgress,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Tooltip,
    Modal,
    List
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { OfficeContext } from "../OfficeContext";
import { UsersContext, useUsers } from "./UsersContext";
import AddUser from "./AddUser";

export default function(props) {
    const { officeId } = useContext(OfficeContext);
    const users = useUsers(officeId);
    const [addUserOpen, setAddUserOpen] = useState(false);

    useEffect(() => {
        users.getUsers();
    }, []);

    return (
        <UsersContext.Provider value={users}>
            <Card>
                <CardHeader title={"Users"} />
                <CardContent>
                    {users.loading ? (
                        <CircularProgress size={30} />
                    ) : (
                        <React.Fragment>
                            <UsersList users={users.users} />
                            <Tooltip title="Add User">
                                <IconButton
                                    onClick={() => {
                                        setAddUserOpen(true);
                                    }}
                                >
                                    <PersonAddIcon />
                                </IconButton>
                            </Tooltip>
                            <Modal
                                style={{ display: "flex" }}
                                open={addUserOpen}
                                onClose={() => {
                                    setAddUserOpen(false);
                                }}
                            >
                                <AddUser
                                    handleClose={() => {
                                        setAddUserOpen(false);
                                    }}
                                />
                            </Modal>
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
        </UsersContext.Provider>
    );
}

function UsersList(props) {
    const users = useContext(UsersContext);
    return (
        <List>
            {users.users.map(user => {
                return <User key={JSON.stringify(user)} user={user} />;
            })}
        </List>
    );
}
