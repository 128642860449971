import React, { useContext, useState } from "react";
import { PagingContext } from "../PagingContext";
import {
    Card,
    ListItemText,
    List,
    CircularProgress,
    Grid,
    IconButton,
    Dialog,
    CardContent,
    CardActions
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { OfficeContext } from "../../OfficeContext";

import { appContext } from "../../../../contexts/app";
import EditMeaningButton from "./EditMeaningButton";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import MeaningButtons from "./MeaningButtons";
import MeaningIcons from "./MeaningIcons";

export default function(props) {
    const { getPaging } = useContext(PagingContext);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const {
        meaningButton,
        meaningButtonId,
        moveMeaningButtonUp,
        moveMeaningButtonDown
    } = props;
    const [loading, setLoading] = useState(false);
    const [editMeaningButtonOpen, setEditMeaningButtonOpen] = useState(false);

    return (
        <Grid item xs={12} lg={5}>
            <List>
                <Card>
                    <CardContent style={{ textAlign: "center" }}>
                        <MeaningIcons
                            iconId={meaningButton.iconId}
                        ></MeaningIcons>
                        {meaningButton.name}
                    </CardContent>
                    <CardActions>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setEditMeaningButtonOpen(true);
                            }}
                            style={{
                                backgroundColor: "white"
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setLoading(true);
                                insyncHttps.paging
                                    .removeMeaningButtonFromOffice({
                                        officeId,
                                        meaningButtonId
                                    })
                                    .then(result => {
                                        setLoading(false);
                                        getPaging();
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        error.setMessage(err.message);
                                    });
                            }}
                            size="small"
                            style={{
                                backgroundColor: "white"
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                moveMeaningButtonUp(meaningButtonId);
                            }}
                            style={{
                                backgroundColor: "white"
                            }}
                            size="small"
                        >
                            <ArrowUpwardIcon></ArrowUpwardIcon>
                        </IconButton>
                        <IconButton
                            size="small"
                            style={{
                                backgroundColor: "white"
                            }}
                            onClick={() => {
                                moveMeaningButtonDown(meaningButtonId);
                            }}
                        >
                            <ArrowDownwardIcon></ArrowDownwardIcon>
                        </IconButton>

                        {loading && <CircularProgress size={10} />}
                    </CardActions>
                </Card>
            </List>

            <Dialog
                open={editMeaningButtonOpen}
                onClose={() => {
                    setEditMeaningButtonOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <EditMeaningButton
                            meaningButtonId={meaningButtonId}
                            handleClose={() => {
                                setEditMeaningButtonOpen(false);
                            }}
                            meaningButton={meaningButton}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}
