import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    Button,
    Grid,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../contexts/app";
import { PagingContext } from "../PagingContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import ModifyPerson from "./ModifyPerson";

export default React.forwardRef((props, ref) => {
    const handleClose = props.handleClose;
    const { error } = useContext(appContext);
    const { getPaging } = useContext(PagingContext);
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const [persons, setPersons] = useState([
        {
            name: "newPerson",
            textColor: "#FFFFFF",
            bgColor: "#" + Math.floor(Math.random() * 16777215).toString(16)
        }
    ]);

    const addPersonToList = () => {
        setPersons([
            ...persons,
            {
                name: "newPerson",
                textColor: "#FFFFFF",
                bgColor: "#" + Math.floor(Math.random() * 16777215).toString(16)
            }
        ]);
    };

    const removePersonFromList = () => {
        if (persons.length > 0) {
            setPersons(persons.slice(0, persons.length - 1));
        }
    };

    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Add Persons" />
            <CardContent>
                <div style={{ margin: "auto" }}>
                    <IconButton
                        onClick={() => {
                            addPersonToList();
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            removePersonFromList();
                        }}
                    >
                        <BackspaceIcon />
                    </IconButton>
                </div>
                <Grid
                    container
                    style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                    {persons.map((person, index) => {
                        return (
                            <ModifyPerson
                                key={index}
                                person={persons[index]}
                                handleChange={person => {
                                    const newPersons = [...persons];
                                    newPersons[index] = { ...person };
                                    setPersons(newPersons);
                                }}
                            />
                        );
                    })}
                </Grid>
                <Button
                    variant="contained"
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.paging
                            .addPersonsToOffice({ officeId, persons })
                            .then(result => {
                                getPaging();
                                setLoading(false);
                                handleClose();
                            })
                            .catch(err => {
                                console.error(err);
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    Submit
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardContent>
        </Card>
    );
});
