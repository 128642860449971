import firebase from "../../firebase";

export default function({ officeId, roomId, name }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingAdmin",
        funcName: "updateRoom",
        data: {
            officeId,
            roomId,
            name
        }
    });
}
