import React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";

export default React.forwardRef((props, ref) => {
    const paymentSource = props.paymentSource;
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Payment Source Details" />
            <CardContent>
                <pre>{JSON.stringify(paymentSource, null, 4)}</pre>
            </CardContent>
        </Card>
    );
});
