import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { usePaging, PagingContext } from "./PagingContext";
import { OfficeContext } from "../OfficeContext";
import Persons from "./Persons/Persons";
import Rooms from "./Rooms/Rooms";
import ErrorStateDisplay from "../../ErrorStateDisplay/ErrorStateDisplay";
import MeaningButtons from "./MeaningButtons/MeaningButtons";
export default function(props) {
    const { officeId } = useContext(OfficeContext);
    const paging = usePaging(officeId);
    useEffect(() => {
        paging.getPaging();
    }, []);

    if (paging.error) {
        return (
            <ErrorStateDisplay
                title="Error Getting Paging"
                message={paging.error}
                retry={paging.getPaging}
            />
        );
    }
    return (
        <PagingContext.Provider value={paging}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Persons persons={paging.persons} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Rooms persons={paging.persons} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <MeaningButtons meaningButtons={paging.meaningButtons} />
                </Grid>
            </Grid>
        </PagingContext.Provider>
    );
}
