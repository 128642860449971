import React, { useState, useContext } from "react";
import {
    Card,
    CardContent,
    TextField,
    CardHeader,
    Select,
    Button,
    MenuItem,
    Grid,
    FormControl,
    InputLabel
} from "@material-ui/core";
import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default function(props) {
    const { officeId } = useContext(OfficeContext);
    const { plans, subscriptions } = useContext(BillingContext);
    const { error } = useContext(appContext);
    const handleClose = props.handleClose;
    const [selectedPlanId, setSelectedPlanId] = useState(null);

    const [plan_quantity, setPlan_quantity] = useState(1);
    return (
        <Card>
            <CardHeader title="Add Subscription" />
            <CardContent>
                <Grid container align="center" spacing={2}>
                    <Grid item xs={12}>
                        <FormControl>
                            <InputLabel>Plan</InputLabel>
                            <Select
                                name="plan"
                                value={selectedPlanId}
                                onChange={e => {
                                    setSelectedPlanId(e.target.value);
                                }}
                            >
                                {plans.plans.map(pl => {
                                    return (
                                        <MenuItem
                                            key={pl.plan.id}
                                            value={pl.plan.id}
                                        >
                                            {pl.plan.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <TextField
                                label="Quantity"
                                type="number"
                                value={plan_quantity}
                                onChange={e => {
                                    setPlan_quantity(e.target.value);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                insyncHttps.billing
                                    .createSubscription({
                                        officeId,
                                        planId: selectedPlanId,
                                        planQuantity: plan_quantity
                                    })
                                    .then(result => {
                                        subscriptions.getSubs();
                                        handleClose();
                                    })
                                    .catch(err => {
                                        error.setMessage(err.message);
                                    });
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
