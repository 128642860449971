import React, { useContext, useState } from "react";
import { PagingContext } from "../PagingContext";
import {
    Card,
    ListItemText,
    List,
    CircularProgress,
    Grid,
    IconButton,
    Dialog
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { OfficeContext } from "../../OfficeContext";

import { appContext } from "../../../../contexts/app";
import EditPerson from "./EditPerson";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default function(props) {
    const { getPaging } = useContext(PagingContext);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { person, personId, movePersonUp, movePersonDown } = props;
    const [loading, setLoading] = useState(false);
    const [editPersonOpen, setEditPersonOpen] = useState(false);

    return (
        <Grid item xs={12} lg={5}>
            <List>
                <Card
                    style={{
                        backgroundColor: person.bgColor
                    }}
                >
                    <ListItemText
                        primary={person.name}
                        style={{
                            color: person.textColor
                        }}
                    />
                    <IconButton
                        size="small"
                        onClick={() => {
                            setEditPersonOpen(true);
                        }}
                        style={{
                            backgroundColor: "white"
                        }}
                    >
                        <EditIcon></EditIcon>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setLoading(true);
                            insyncHttps.paging
                                .removePersonFromOffice({
                                    officeId,
                                    personId
                                })
                                .then(result => {
                                    setLoading(false);
                                    getPaging();
                                })
                                .catch(err => {
                                    setLoading(false);
                                    error.setMessage(err.message);
                                });
                        }}
                        size="small"
                        style={{
                            backgroundColor: "white"
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            movePersonUp(personId);
                        }}
                        style={{
                            backgroundColor: "white"
                        }}
                        size="small"
                    >
                        <ArrowUpwardIcon></ArrowUpwardIcon>
                    </IconButton>
                    <IconButton
                        size="small"
                        style={{
                            backgroundColor: "white"
                        }}
                        onClick={() => {
                            movePersonDown(personId);
                        }}
                    >
                        <ArrowDownwardIcon></ArrowDownwardIcon>
                    </IconButton>

                    {loading && <CircularProgress size={10} />}
                </Card>
            </List>

            <Dialog
                open={editPersonOpen}
                onClose={() => {
                    setEditPersonOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <EditPerson
                            personId={personId}
                            handleClose={() => {
                                setEditPersonOpen(false);
                            }}
                            person={person}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}
