import React, { useState, useContext } from "react";

import ReportContainer from "./ReportContainer";
import {
    Card,
    CardContent,
    Button,
    Modal,
    Dialog,
    Toolbar,
    IconButton,
    AppBar
} from "@material-ui/core";
import VisitReport1Context from "../../VisitReport1Context";
import ReportContent from "./ReportContent";
import CloseIcon from "@material-ui/icons/Close";
import ReactToPrint from "react-to-print";
export default function(props) {
    const reportCtx = useContext(VisitReport1Context);
    const [dialogOpen, setDialogOpen] = useState(false);
    return (
        <Card>
            <CardContent>
                {reportCtx.reportTime > 0 && (
                    <React.Fragment>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                setDialogOpen(true);
                            }}
                        >
                            expand
                        </Button>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Button variant="contained" size="small">print</Button>
                                );
                            }}
                            content={() => {
                                return reportCtx.printRef.current;
                            }}
                        ></ReactToPrint>
            
                        <Dialog
                            // BackdropProps={{style: {padding: "10px"}}}
                            fullScreen
                            open={dialogOpen}
                            onClose={() => {
                                setDialogOpen(false);
                            }}
                        >
                            <AppBar position="relative">
                                <Toolbar style={{ minHeight: "0" }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setDialogOpen(false);
                                        }}
                                    >
                                        <CloseIcon
                                            style={{ color: "white" }}
                                        ></CloseIcon>
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <ReportContent></ReportContent>
                        </Dialog>
                        <ReportContainer></ReportContainer>
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    );
}
